import { graphql } from '~/graphql/generated/gql';

export const systemConfigFragment = graphql(`
  fragment systemConfigFields on SystemConfig {
    isSkippable
    repeatMode
    autoload {
      isAutoload
      servingUrlPath
    }
  }
`);

export const activityInitialValueUnionFragment = graphql(`
  fragment activityInitialValueUnionFields on ActivityInitialValueUnion {
    ... on OccupationSearchInitialValue {
      __typename
      OccupationSearchInitialValue {
        itemsAddedToVisionBoard
      }
    }
    ... on JobGridInitialValue {
      __typename
    }

    ... on JobOverviewInitialValue {
      __typename
    }

    ... on JobResponsibilitiesInitialValue {
      __typename
    }

    ... on JobConsiderationsInitialValue {
      __typename
    }

    ... on JobMatchInitialValue {
      __typename
    }

    ... on InstitutionSearchInitialValue {
      __typename
      InstitutionSearchInitialValue {
        itemsAddedToVisionBoard
      }
    }

    ... on EducationPathwaysInitialValue {
      __typename
      EducationPathwaysInitialValue {
        itemsAddedToVisionBoard
      }
    }

    ... on CareerVisionBoardInitialValue {
      __typename
      CareerVisionBoardInitialValue {
        answer
      }
    }

    ... on FillCareerProfileInitialValue {
      __typename
      FillCareerProfileInitialValue {
        answer
      }
    }

    ... on CareerVisionBoardReviewInitialValue {
      __typename
      CareerVisionBoardReviewInitialValue {
        answer
      }
    }

    ... on AcademicPlanInitialValue {
      __typename
      AcademicPlanInitialValue {
        answer
      }
    }

    ... on YourAssistantInitialValue {
      __typename
      YourAssistantInitialValue {
        answer
      }
    }

    ... on InterestSelectionInitialValue {
      __typename
      InterestSelectionInitialValue {
        selectedInterests
      }
    }

    ... on ValuePropositionInitialValue {
      __typename
      ValuePropositionInitialValue {
        answer
      }
    }

    ... on PreplanAIInitialValue {
      __typename
      PreplanAIInitialValue {
        summary
        conversation
        persona
        questions {
          number
          question
          answer
          options {
            id
            value
            label
          }
        }
      }
    }

    ... on PreplanSummaryInitialValue {
      __typename
      PreplanSummaryInitialValue {
        answer
      }
    }

    ... on PreplanPreferencesInitialValue {
      __typename
      PreplanPreferencesInitialValue {
        selectedTheme
      }
    }
  }
`);

export const activityConfigUnionFragment = graphql(`
  fragment activityConfigUnionFields on ActivityConfigUnion {
    ... on InterestSelectionConfig {
      __typename
      InterestSelectionConfig {
        prompt
        options {
          label
          value
          image
        }
      }
    }

    ... on ValuePropositionConfig {
      __typename
      ValuePropositionConfig {
        prompt
        valuePropositions {
          promptImagePath
          description
        }
      }
    }

    ... on PreplanSummaryConfig {
      __typename
      PreplanSummaryConfig {
        prompt
        summary {
          summary
          sentenceSummary
        }
        traits {
          title
          description
          icon
        }
        potentialJobs {
          industry_name
          careers {
            why_suitable
            career_name
          }
        }
        fieldOfInterest {
          industry_name
        }
        selectedInterests
        raisecProfile {
          acronym
          explanation
        }
      }
    }

    ... on YourAssistantConfig {
      __typename
      YourAssistantConfig {
        heading
        bodyText
      }
    }

    ... on PreplanPreferencesConfig {
      __typename
      PreplanPreferencesConfig {
        prompt
      }
    }

    ... on PreplanAIConfig {
      __typename
      PreplanAIConfig {
        interests
      }
    }

    ... on OccupationSearchConfig {
      __typename
      OccupationSearchConfig {
        heading
        assistantPrompt
        fieldOfInterest {
          industry_id
          industry_name
        }
        onetSkillName
        interest
        jobsSaved {
          id
          name
        }
      }
    }

    ... on JobGridConfig {
      __typename
      JobGridConfig {
        data {
          jobs {
            id
            title
            wage
            thumbImageUrl
          }
          topic
        }
      }
    }

    ... on JobOverviewConfig {
      __typename
      JobOverviewConfig {
        data {
          image
          description
        }
      }
    }

    ... on JobResponsibilitiesConfig {
      __typename
      JobResponsibilitiesConfig {
        data {
          title
          responsibilities
        }
      }
    }

    ... on JobConsiderationsConfig {
      __typename
      JobConsiderationsConfig {
        data {
          considerations {
            title
            description
          }
        }
      }
    }

    ... on JobMatchConfig {
      __typename
      JobMatchConfig {
        data {
          matches
        }
      }
    }

    ... on JobProsAndConsConfig {
      __typename
      JobProsAndConsConfig {
        data {
          pros
          cons
        }
      }
    }

    ... on InstitutionSearchConfig {
      __typename
      InstitutionSearchConfig {
        heading
        fieldOfInterest {
          industry_id
          industry_name
        }
        addressStateName
        addressStateCode
        institutionsSaved {
          id
          name
        }
      }
    }

    ... on AcademicPlanConfig {
      __typename
      AcademicPlanConfig {
        heading
        assistantPrompt
        buttonTxt
        data {
          id
          name
          academic_plan {
            grade
            subjects {
              id
              course
              reasons
            }
            electives {
              id
              course
              reasons
            }
          }
        }
        fieldOfInterest {
          industry_id
          industry_name
        }
      }
    }

    ... on EducationPathwaysConfig {
      __typename
      EducationPathwaysConfig {
        heading
        assistantPrompt
        buttonTxt
        data {
          id
          name
          pathways {
            id
            name
            description
          }
        }
        fieldOfInterest {
          industry_id
          industry_name
        }
        pathwayTypePreference {
          id
          name
        }
      }
    }
  }
`);
